.home {
  box-sizing: border-box;
  // border: 2px solid rgb(255, 0, 0);
  display: flex;
  // padding-top: 5%;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-image: url('./images/victorianbg.jpg');

  img.logo {
    width: 40%;
    height: auto;
    position: absolute;
    top: 12%;
    left: 55%;
    z-index: 1;
  }
  .home-app {
    display: flex;
    justify-content: center;
    min-width: 100%;
    background-color: rgba(0, 0, 0, 0.853);
    .image-gallery {
      display: flex;
      justify-content: center;
      min-width: 100%;
      .image-gallery-content {
        width: 100%;
        .image-gallery-swipe {
          .image-gallery-slides {
            width: 100%;
            .image-gallery-image {
              object-position: center;
              width: 100%;
              box-shadow: inset 0 0 50px rgb(0, 0, 0);
            }
          }
        }
      }
    }
  }
  .current {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    min-height: 100%;
    justify-content: center;
    .right {
      display: flex;
      flex-wrap: wrap;
      min-width: 1200px;
      width: 100%;
      justify-content: space-evenly;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 1rem;
      text-align: center;
      justify-content: center;
      .social-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        background-color: rgba(0, 0, 0, 0.82);
        width: 90%;
        a {
          padding: 2rem;
        }
      }
      .social-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        border: 0;
        filter: drop-shadow(0 0 20px rgb(126, 2, 52));

        :hover {
          filter: drop-shadow(0 0 20px rgb(126, 2, 52));
        }
        img {
          width: 325px;
          height: 175px;
        }
        a {
          border: none;
        }
      }
    }
    .left {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      padding: 1rem;
      background-color: rgba(0, 0, 0, 0.503);
    }
    .poster {
      // padding: 1rem;
      display: flex;
      justify-content: center;
      background-size: cover;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      width: 50%;
      min-height: 800px;
      min-width: 500px;
      background-color: #000;
    }
    .eow {
      background-image: url('./images/TheEndOfWonderland_Poster_small.jpg');
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;
      resize: both;
    }
    .uu {
      background-image: url('./images/Up_Uranus_poster_small.jpg');
      background-position: right center;
      background-repeat: no-repeat;
      align-content: flex-start;
      background-size: contain;
      resize: both;
      // align-self: flex-end;
      // padding-left: 3rem;
      // padding-right: 3rem;
    }
    .members {
      background-image: url('./images/VIP_ad_small.jpg');
      background-position: left center;
      background-repeat: no-repeat;
      background-size: contain;
      resize: both;
    }
    .movie-wrapper {
      background-color: #000;
      width: 100%;
      min-width: 1200px;
      max-width: 1700px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 1rem;
    }
    .end {
      justify-content: flex-end;
    }
    .start {
      justify-content: flex-start;
    }
   .vip-image {
     border: none;
     margin-top: 3rem;
     background-color: #000;
     width: 234px;
     height: 93px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      // :hover {
        // filter: drop-shadow(0 0 20px rgb(126, 2, 52));
      // }
      .light{
        background-image: url('./images/VIP-access-light.png');
      }
      .dark {
        background-image: url('./images/VIP-access.png');
      }
      a{
        width: 100%;
        height: 100%;
      }
    }
    .movie-info {
      background-color: rgba(0, 0, 0, 0.805);
      height: 100%;
      min-height: 800px;
      max-height: 800px;
      width: 40%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
        height: auto;
      }
      .image100 {
        width: 100%;
        height: auto;
      }
      p {
        font-size: 1.2rem;
        line-height: 1.5rem;
        width: 90%;
      }
      a {
        font-family: 'kabel-heavyheavy';
        font-size: 1.4rem;
        line-height: 1.2rem;
      }
    }

    .public-events {
      display: flex;
      background-image: url('./images/home/publicBanner.jpg');
      background-color: rgba(0, 0, 0, 0.7);
      margin-top: 10%;
      width: 50%;
      min-height: 400px;
      background-position: center center;
      background-size: 120%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 100%;
      font-family: 'Kabel-heavyheavy', sans-serif;
      section.events {
        max-width: 50%;
      }
      img {
        margin-top: -5%;
        padding-right: 2rem;
      }
    }
  }

  .social-container {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    a {
      font-family: 'Kabel-heavyheavy', sans-serif;
      font-size: 1.2rem;
      padding: 1rem;
      width: 30%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
    }
    img{
      width: 100%;
      height: auto;

    }
  }
}
@media (max-width: 1200px) {
  .home{
    .current{
      .movie-wrapper{
        min-width: 600px;
        max-width: 1200px;
      }
      .eow{

        min-height: 500px;
        min-width: 300px;
      }
      .uu{
        min-height: 500px;
        min-width: 300px;
      }
      .members{
        min-height: 500px;
        min-width: 300px;
      }
      .poster{
        width:40%;

      }
      .vip-image{
        margin: 0;
        padding-top: 1rem;
      }
      .movie-info{
        width: 50%;
        min-height: 400px;
        max-height: 500px;
        img{
          width: 60%;
          margin-top: 1.5rem;
        }
        button{
          display: flex;
        }
        // iframe{
        //   display: none;

        // }
        svg{
          width: 1.5rem;
          height: 1.5rem;
          margin: .5rem;
        }

        a{
          font-size: 1rem;
        }
        p{
          font-size: 1rem;
          line-height: 1.3rem;
        }
      }
      .right{
        min-width: 800px;
        .social-buttons{
          img{
           width: 250px;
            height: auto;
          }
        }
      }
    }
  }

}
@media (max-width: 800px){
   .home{
    .current{
      .movie-wrapper{
        min-width: 360px;
        max-width: 799px;
      }
      .eow{

        min-height: 300px;
        min-width: 200px;
      }
      .uu{
        min-height: 300px;
        min-width: 200px;
      }
      .members{
        min-height: 300px;
        min-width: 200px;
      }
      .poster{
        width:30%;

      }
      .vip-image{
        margin: 0;
        padding-top: .5rem;
      }
      .start{
        justify-content: space-evenly;
      }
      .end{
        justify-content: space-evenly;
      }
       .movie-info{
        width: 50%;
        min-height: 400px;
        max-height: 500px;
        img{
          width: 60%;
          margin-top: 1.5rem;
        }
        button{
          display: flex;
        }
        // iframe{
        //   display: none;

        // }
        svg{
          width: 1.2rem;
          height: 1.2rem;
          margin: .5rem;
        }

        a{
          font-size: .8rem;
        }
        p{
          font-size: 1rem;
          line-height: 1.3rem;
          width: 100%;
        }
      }
      .right{
        min-width: 300px;
        .social-buttons{
          img{
           width: 250px;
            height: auto;
          }
        }
      }

    }
  }
}
@media (max-width: 600px){
   .home{
    .current{
      .movie-wrapper{
        min-width: 360px;
        max-width: 500px;
      }
      .eow{
         background-position: center center;
        min-height: 400px;
        min-width: 350px;
      }
      .uu{
                 background-position: center center;

        min-height: 400px;
        min-width: 350px;
      }
      .members{
                 background-position: center center;

        min-height: 400px;
        min-width: 350px;
      }
      .poster{
        width:30%;

      }
      .vip-image{
        margin: 0;
        padding-top: .5rem;
      }
      .start{
        justify-content: space-evenly;
      }
      .end{
        justify-content: space-evenly;
        flex-direction: column-reverse;
      }
       .movie-info{
        width: 90%;
        min-height: 300px;
        max-height: 500px;

        a{
          font-size: 1rem;
        }
        p{
          font-size: 1rem;
          line-height: 1.3rem;
          width: 95%;
        }
      }
      .right{
        min-width: 400px;
        .social-buttons{
          img{
           width: 150px;
            height: auto;
          }
        }
      }

    }
  }
}
