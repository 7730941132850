.bio-container {
  display: flex;
  width: 100%;
  .bio {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;
    width: 100%;
  }
  .bio::before {
    content: '';
    background-image: url('./images/goddess.jpg');
    background-color: black;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 850px auto;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 1;
  }
  .text {
    position: relative;
    right: 0;
    top: 0;
    left: -100px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: right;
    width: 50%;
    // margin-right: 5%;
    // height: 100vh;
    // overflow: scroll;
    p {
      font-family: 'kabel-heavyheavy';
      text-align: center;
      padding: 1.3rem;
      font-size: 1.5rem;
      color: #fff;
      filter: drop-shadow(2px 2px 2px #000);
    }
    img {
      margin-top: 30%;
      width: 70%;
      height: auto;
    }
  }
}
// @media (max-width: 1800px) {
//   .bio-container {
//     .bio {
//       background-size: 800px auto;
//       align-items: center;
//     }
//     .text {
//       margin-right: 3%;

//       p {
//         padding: 0.7rem;
//         font-size: 1.2rem;
//       }
//     }
//   }
// }
@media (max-width: 1600px) {
  .bio-container {
        .bio::before {
      background-size: 750px auto;
    }
    .text {
      margin-right: 1%;
      p {
        padding: 0.5rem;
        font-size: 1.2rem;
      }
    }
  }
}
@media (max-width: 1200px) {
  .bio-container {
        .bio::before {

    }
    .text {
      left: 0px;
      p {
        padding: 0.5rem;
        font-size: 1rem;
      }
      img {
        margin-top: 15%;
      }
    }
  }
}
@media (max-width: 801px) {
  .bio-container {
    .bio::before {
      background-size: 650px auto;
      opacity: 0.75;
    }
    .text {
      width: 50%;
      p {
        width: 100%;
        font-size: 01rem;
      }
    }
  }
}
@media (max-width: 600px) {
  .bio-container {
    .bio::before {
      opacity: 0.5;
    }
    .text {
      min-width: 320px;
      width: 100%;
      p {
        width: 90%;
        font-size: 0.9rem;
      }
      img {
        margin-top: 0%;
      }
    }
  }
}
// ::before {
//       content: "";
//       background-image: url('https://placekitten.com/1200/800');
//       background-size: cover;
//       position: absolute;
//       top: 0px;
//       right: 0px;
//       bottom: 0px;
//       left: 0px;
//       opacity: 0.75;
// }
