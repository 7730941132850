@charset "UTF-8";
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 10, 2022 */
@font-face {
  font-family: "kabel-heavyheavy";
  src: url("./stylesheets/fonts/webfontkit-20220310-004149/kabel-heavy-webfont.woff2") format("woff2"), url("./stylesheets/fonts/webfontkit-20220310-004149/kabel-heavy-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "victorian_parlor_vintage_alternatvictorianparlorvintagealternatefree";
  src: url("./stylesheets/fonts/webfontkit-20220310-004149/victorian_parlor_vintage_alternate_free-webfont.woff2") format("woff2"), url("./stylesheets/fonts/webfontkit-20220310-004149/victorian_parlor_vintage_alternate_free-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "little_lord_fontleroy_nfRg";
  src: url("./stylesheets/fonts/webfontkit-20220310-004149/littlelordfontleroy.regular-webfont.woff2") format("woff2"), url("./stylesheets/fonts/webfontkit-20220310-004149/littlelordfontleroy.regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.home {
  box-sizing: border-box;
  display: flex;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-image: url("./images/victorianbg.jpg");
}
.home img.logo {
  width: 40%;
  height: auto;
  position: absolute;
  top: 12%;
  left: 55%;
  z-index: 1;
}
.home .home-app {
  display: flex;
  justify-content: center;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.853);
}
.home .home-app .image-gallery {
  display: flex;
  justify-content: center;
  min-width: 100%;
}
.home .home-app .image-gallery .image-gallery-content {
  width: 100%;
}
.home .home-app .image-gallery .image-gallery-content .image-gallery-swipe .image-gallery-slides {
  width: 100%;
}
.home .home-app .image-gallery .image-gallery-content .image-gallery-swipe .image-gallery-slides .image-gallery-image {
  object-position: center;
  width: 100%;
  box-shadow: inset 0 0 50px black;
}
.home .current {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  min-height: 100%;
  justify-content: center;
}
.home .current .right {
  display: flex;
  flex-wrap: wrap;
  min-width: 1200px;
  width: 100%;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  text-align: center;
  justify-content: center;
}
.home .current .right .social-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.82);
  width: 90%;
}
.home .current .right .social-container a {
  padding: 2rem;
}
.home .current .right .social-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border: 0;
  filter: drop-shadow(0 0 20px #7e0234);
}
.home .current .right .social-buttons :hover {
  filter: drop-shadow(0 0 20px #7e0234);
}
.home .current .right .social-buttons img {
  width: 325px;
  height: 175px;
}
.home .current .right .social-buttons a {
  border: none;
}
.home .current .left {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.503);
}
.home .current .poster {
  display: flex;
  justify-content: center;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  min-height: 800px;
  min-width: 500px;
  background-color: #000;
}
.home .current .eow {
  background-image: url("./images/TheEndOfWonderland_Poster_small.jpg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  resize: both;
}
.home .current .uu {
  background-image: url("./images/Up_Uranus_poster_small.jpg");
  background-position: right center;
  background-repeat: no-repeat;
  align-content: flex-start;
  background-size: contain;
  resize: both;
}
.home .current .members {
  background-image: url("./images/VIP_ad_small.jpg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  resize: both;
}
.home .current .movie-wrapper {
  background-color: #000;
  width: 100%;
  min-width: 1200px;
  max-width: 1700px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem;
}
.home .current .end {
  justify-content: flex-end;
}
.home .current .start {
  justify-content: flex-start;
}
.home .current .vip-image {
  border: none;
  margin-top: 3rem;
  background-color: #000;
  width: 234px;
  height: 93px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.home .current .vip-image .light {
  background-image: url("./images/VIP-access-light.png");
}
.home .current .vip-image .dark {
  background-image: url("./images/VIP-access.png");
}
.home .current .vip-image a {
  width: 100%;
  height: 100%;
}
.home .current .movie-info {
  background-color: rgba(0, 0, 0, 0.805);
  height: 100%;
  min-height: 800px;
  max-height: 800px;
  width: 40%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home .current .movie-info img {
  width: 50%;
  height: auto;
}
.home .current .movie-info .image100 {
  width: 100%;
  height: auto;
}
.home .current .movie-info p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  width: 90%;
}
.home .current .movie-info a {
  font-family: "kabel-heavyheavy";
  font-size: 1.4rem;
  line-height: 1.2rem;
}
.home .current .public-events {
  display: flex;
  background-image: url("./images/home/publicBanner.jpg");
  background-color: rgba(0, 0, 0, 0.7);
  margin-top: 10%;
  width: 50%;
  min-height: 400px;
  background-position: center center;
  background-size: 120%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  font-family: "Kabel-heavyheavy", sans-serif;
}
.home .current .public-events section.events {
  max-width: 50%;
}
.home .current .public-events img {
  margin-top: -5%;
  padding-right: 2rem;
}
.home .social-container {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.home .social-container a {
  font-family: "Kabel-heavyheavy", sans-serif;
  font-size: 1.2rem;
  padding: 1rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
.home .social-container img {
  width: 100%;
  height: auto;
}

@media (max-width: 1200px) {
  .home .current .movie-wrapper {
    min-width: 600px;
    max-width: 1200px;
  }
  .home .current .eow {
    min-height: 500px;
    min-width: 300px;
  }
  .home .current .uu {
    min-height: 500px;
    min-width: 300px;
  }
  .home .current .members {
    min-height: 500px;
    min-width: 300px;
  }
  .home .current .poster {
    width: 40%;
  }
  .home .current .vip-image {
    margin: 0;
    padding-top: 1rem;
  }
  .home .current .movie-info {
    width: 50%;
    min-height: 400px;
    max-height: 500px;
  }
  .home .current .movie-info img {
    width: 60%;
    margin-top: 1.5rem;
  }
  .home .current .movie-info button {
    display: flex;
  }
  .home .current .movie-info svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem;
  }
  .home .current .movie-info a {
    font-size: 1rem;
  }
  .home .current .movie-info p {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .home .current .right {
    min-width: 800px;
  }
  .home .current .right .social-buttons img {
    width: 250px;
    height: auto;
  }
}
@media (max-width: 800px) {
  .home .current .movie-wrapper {
    min-width: 360px;
    max-width: 799px;
  }
  .home .current .eow {
    min-height: 300px;
    min-width: 200px;
  }
  .home .current .uu {
    min-height: 300px;
    min-width: 200px;
  }
  .home .current .members {
    min-height: 300px;
    min-width: 200px;
  }
  .home .current .poster {
    width: 30%;
  }
  .home .current .vip-image {
    margin: 0;
    padding-top: 0.5rem;
  }
  .home .current .start {
    justify-content: space-evenly;
  }
  .home .current .end {
    justify-content: space-evenly;
  }
  .home .current .movie-info {
    width: 50%;
    min-height: 400px;
    max-height: 500px;
  }
  .home .current .movie-info img {
    width: 60%;
    margin-top: 1.5rem;
  }
  .home .current .movie-info button {
    display: flex;
  }
  .home .current .movie-info svg {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.5rem;
  }
  .home .current .movie-info a {
    font-size: 0.8rem;
  }
  .home .current .movie-info p {
    font-size: 1rem;
    line-height: 1.3rem;
    width: 100%;
  }
  .home .current .right {
    min-width: 300px;
  }
  .home .current .right .social-buttons img {
    width: 250px;
    height: auto;
  }
}
@media (max-width: 600px) {
  .home .current .movie-wrapper {
    min-width: 360px;
    max-width: 500px;
  }
  .home .current .eow {
    background-position: center center;
    min-height: 400px;
    min-width: 350px;
  }
  .home .current .uu {
    background-position: center center;
    min-height: 400px;
    min-width: 350px;
  }
  .home .current .members {
    background-position: center center;
    min-height: 400px;
    min-width: 350px;
  }
  .home .current .poster {
    width: 30%;
  }
  .home .current .vip-image {
    margin: 0;
    padding-top: 0.5rem;
  }
  .home .current .start {
    justify-content: space-evenly;
  }
  .home .current .end {
    justify-content: space-evenly;
    flex-direction: column-reverse;
  }
  .home .current .movie-info {
    width: 90%;
    min-height: 300px;
    max-height: 500px;
  }
  .home .current .movie-info a {
    font-size: 1rem;
  }
  .home .current .movie-info p {
    font-size: 1rem;
    line-height: 1.3rem;
    width: 95%;
  }
  .home .current .right {
    min-width: 400px;
  }
  .home .current .right .social-buttons img {
    width: 150px;
    height: auto;
  }
}
.image-gallery-icon {
  color: #fff;
  transition: all 0.3s ease-out;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  filter: drop-shadow(0 2px 2px #1a1a1a);
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover {
    color: #337ab7;
  }
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1.1);
  }
}
.image-gallery-icon:focus {
  outline: 2px solid #337ab7;
}

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px;
}
.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
  height: 28px;
  width: 28px;
}
@media (max-width: 768px) {
  .image-gallery-fullscreen-button,
.image-gallery-play-button {
    padding: 15px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 480px) {
  .image-gallery-fullscreen-button,
.image-gallery-play-button {
    padding: 10px;
  }
  .image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 16px;
    width: 16px;
  }
}

.image-gallery-fullscreen-button {
  right: 0;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 60px;
}
@media (max-width: 768px) {
  .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 72px;
    width: 36px;
  }
}
@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
  }
}
.image-gallery-left-nav[disabled],
.image-gallery-right-nav[disabled] {
  cursor: disabled;
  opacity: 0.6;
  pointer-events: none;
}

.image-gallery-left-nav {
  left: 0;
}

.image-gallery-right-nav {
  right: 0;
}

.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
}
.image-gallery.fullscreen-modal {
  background: #000;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 5;
}
.image-gallery.fullscreen-modal .image-gallery-content {
  top: 50%;
  transform: translateY(-50%);
}

.image-gallery-content {
  width: 100%;
  position: relative;
  max-width: max-content;
  max-height: min-content;
  line-height: 0;
  top: 0;
  z-index: 0;
}
.image-gallery-content.fullscreen {
  background: #000;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(90vh - 80px);
}
.image-gallery-slide-wrapper {
  position: relative;
}
.image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
  display: inline-block;
  width: calc(100% - 110px);
}
@media (max-width: 768px) {
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    width: calc(100vh - 87px);
  }
}
.image-gallery-slide-wrapper.image-gallery-rtl {
  direction: rtl;
}

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center;
}

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.image-gallery-slide.center {
  position: relative;
}
.image-gallery-slide .image-gallery-image {
  max-width: 100%;
  object-fit: contain;
  height: 100%;
}
.image-gallery-slide .image-gallery-image img {
  max-height: 90%;
}
.image-gallery-slide .image-gallery-description {
  background: rgba(0, 0, 0, 0.4);
  bottom: 70px;
  color: #fff;
  left: 0;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}
@media (max-width: 768px) {
  .image-gallery-slide .image-gallery-description {
    bottom: 45px;
    font-size: 0.8em;
    padding: 8px 15px;
  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;
}
.image-gallery-bullets .image-gallery-bullets-container {
  margin: 0;
  padding: 0;
  text-align: center;
}
.image-gallery-bullets .image-gallery-bullet {
  appearance: none;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px #1a1a1a;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  outline: none;
  padding: 5px;
  transition: all 0.2s ease-out;
}
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 0 3px;
    padding: 3px;
  }
}
@media (max-width: 480px) {
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2.7px;
  }
}
.image-gallery-bullets .image-gallery-bullet:focus {
  transform: scale(1.2);
  background: #337ab7;
  border: 1px solid #337ab7;
}
.image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.2);
  border: 1px solid #fff;
  background: #fff;
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-bullets .image-gallery-bullet:hover {
    background: #337ab7;
    border: 1px solid #337ab7;
  }
  .image-gallery-bullets .image-gallery-bullet.active:hover {
    background: #337ab7;
  }
}

.image-gallery-thumbnails-wrapper {
  position: relative;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  touch-action: pan-y;
}
.image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical {
  touch-action: pan-x;
}
.image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
  direction: rtl;
}
.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    width: 81px;
  }
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
  height: 100%;
  width: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
  display: block;
  margin-right: 0;
  padding: 0;
}
.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 0;
  margin-top: 2px;
}
.image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 3px;
  }
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnails {
    padding: 3px 0;
  }
}
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  transition: border 0.3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail {
    border: 3px solid transparent;
    width: 81px;
  }
}
.image-gallery-thumbnail + .image-gallery-thumbnail {
  margin-left: 2px;
}
.image-gallery-thumbnail .image-gallery-thumbnail-inner {
  display: block;
  position: relative;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  outline: none;
  border: 4px solid #337ab7;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 3px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) {
  .image-gallery-thumbnail:hover {
    outline: none;
    border: 4px solid #337ab7;
  }
}
@media (hover: hover) and (pointer: fine) and (max-width: 768px) {
  .image-gallery-thumbnail:hover {
    border: 3px solid #337ab7;
  }
}

.image-gallery-thumbnail-label {
  box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 0 2px 2px #1a1a1a;
  transform: translateY(-50%);
  white-space: normal;
  width: 100%;
}
@media (max-width: 768px) {
  .image-gallery-thumbnail-label {
    font-size: 0.8em;
    line-height: 0.8em;
  }
}

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4;
}
@media (max-width: 768px) {
  .image-gallery-index {
    font-size: 0.8em;
    padding: 5px 10px;
  }
}

div.gallery-list {
  max-width: 100%;
}
div.gallery-list ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
div.gallery-list li {
  width: 25%;
  margin: 0 1rem;
  padding: 0;
}
div.gallery-list img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

section.app {
  max-width: 100%;
  min-height: 90vh;
  display: flex;
  margin: 1rem;
}
section.app .image-gallery-content {
  position: unset;
  width: 90vw;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
section.app .image-gallery-slide.center {
  max-width: fit-content;
  margin: auto;
}

.gallery-container {
  width: 100%;
  height: 100%;
}

.app-header {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.play-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.play-button:after {
  content: "";
  display: block;
  position: absolute;
  top: 16.5px;
  left: 40px;
  margin: 0 auto;
  border-style: solid;
  border-width: 12.5px 0 12.5px 20px;
  border-color: transparent transparent transparent white;
}

.close-video::before {
  content: "✖";
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  padding: 20px;
  z-index: 1;
  line-height: 0.7;
  display: block;
  color: #fff;
}

.video-wrapper {
  position: relative;
  padding: 33.35% 0;
  /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.app .image-gallery {
  margin: 0 auto;
  display: flex;
}

.app-buttons li {
  display: block;
}

@media (max-width: 768px) {
  .app-header {
    font-size: 20px;
  }

  .app-buttons li {
    display: block;
    margin: 10px 0;
  }

  .app-buttons li + li {
    padding: 0;
  }

  .play-button {
    height: 40px;
    width: 65px;
  }

  .play-button:after {
    top: 11px;
    left: 27px;
    border-width: 8.5px 0 8.5px 12px;
  }

  .close-video::before {
    font-size: 16px;
    padding: 15px;
  }
}
@media (max-width: 1024px) {
  .app .image-gallery {
    width: 100%;
    height: 100%;
  }
}
.app-interval-input-group {
  display: table;
}

.app-interval-label {
  display: table-cell;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 3px solid #ccc;
  border-right: none;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-interval-input {
  -webkit-appearance: none;
  display: table-cell;
  margin: 0;
  padding: 9px;
  border-radius: 5px;
  font-size: 14px;
  border: 3px solid #ccc;
  background: #fff;
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.app-interval-input {
  width: 65px;
}

.video-preview {
  display: flex;
}

.ff-inactive {
  border-radius: 20px;
}

.video-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  min-width: 90%;
}
.video-list .gif-holder {
  padding: 1rem;
}
.video-list .gif-holder div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.video-list .freezeframe {
  padding: 1rem;
  margin: 1rem;
  border-radius: 20px;
  filter: drop-shadow(0 0 0.3rem #de59e8);
  background-color: rgba(0, 0, 0, 0.8);
}

.navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, black 25%, black 100%);
}
.navbar ul {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  list-style: none;
  padding: 0;
}
.navbar ul a {
  display: flex;
  align-items: center;
  align-content: center;
}
.navbar a {
  z-index: 0;
  display: flex;
  text-decoration: none;
  font-family: "kabel-heavyheavy";
  font-size: 1.6rem;
}

div.hamburg-icon {
  color: #ca3994;
  position: absolute;
  padding: 0.5rem;
  top: -60px;
  right: 0;
  align-items: center;
  height: 100%;
  align-self: flex-end;
  opacity: 0;
  z-index: 100;
}

.eighteen {
  width: 2rem;
  position: relative;
  top: -2rem;
  right: -2rem;
  color: white;
  font-size: 1rem;
}

.youtube-nav {
  padding: 0 0.5rem 0 0.5rem;
  margin-top: auto;
  color: white;
  font-size: 1rem;
}
.youtube-nav svg {
  width: 1.2rem;
  height: 1.2rem;
}

.menu-container {
  display: flex;
  flex-direction: column;
}
.menu-container .menu {
  z-index: -10;
  width: 20%;
  position: absolute;
  top: 2.5rem;
  right: 0;
  flex-direction: column;
  height: 100vh;
  background: linear-gradient(270deg, black 0%, black 90%, rgba(0, 0, 0, 0) 100%);
}
.menu-container .menu :hover {
  display: flex;
  opacity: 1;
}
.menu-container .active {
  display: flex;
  opacity: 1;
}
.menu-container .active a {
  margin-top: 1rem;
  margin-left: 2rem;
  padding-left: 1.5rem;
}
.menu-container .inactive {
  display: none;
  opacity: 0;
}

.close {
  visibility: hidden;
}

.open {
  visibility: visible;
}

.vip-menu-toggle {
  display: flex;
}
.vip-menu-toggle svg {
  position: absolute;
  top: 1.5rem;
  right: 2%;
  color: #ca3994;
  width: 1.3rem;
}

.vip-close {
  z-index: 200;
}

@media (max-width: 1200px) {
  .navbar a {
    margin: 0 0rem 0 0;
    padding-right: 0;
    font-size: 1.2rem;
  }

  .youtube-nav svg {
    width: 0.9rem;
    height: 0.9rem;
  }

  .eighteen {
    top: -1.7rem;
    right: -2.4rem;
    font-size: 0.8rem;
  }
}
@media (max-width: 800px) {
  .navbar {
    align-items: flex-end;
    background: transparent;
    position: relative;
    top: 0;
    bottom: 0;
    margin-top: 2rem;
  }
  .navbar a {
    text-align: left;
    padding: 0 1.2rem 0 1.2rem;
  }
  .navbar ul {
    opacity: 0;
    height: 100vh;
    width: 250px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background: linear-gradient(270deg, black 0%, black 80%, rgba(0, 0, 0, 0) 100%);
  }
  .navbar li {
    padding: 0.5rem;
  }
  .navbar .active {
    display: flex;
    opacity: 1;
  }
  .navbar .active a {
    margin-top: 0rem;
    margin-left: 4rem;
  }
  .navbar .inactive {
    display: none;
  }

  .menu-container .menu {
    top: 13.2rem;
    right: 0rem;
    width: 230px;
  }
  .menu-container .menu :hover {
    display: flex;
  }
  .menu-container .menu a {
    padding-left: 2rem;
  }

  div.hamburg-icon {
    top: 0;
    opacity: 1;
  }
  div.hamburg-icon button {
    z-index: 100;
  }

  .eighteen {
    width: 2rem;
    position: relative;
    top: -0.3rem;
    right: -0.3rem;
    color: white;
    font-size: 1rem;
  }

  .youtube-nav svg {
    width: 0.9rem;
    height: 0.9rem;
  }
}
.vip {
  background-color: black;
}
.vip .slide-show {
  display: flex;
  width: 90%;
}
.vip .slide-show .slide-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.vip .slide-show .slide-container .slide {
  width: 100%;
  transition: transform 0.5s ease-in-out;
}
.vip .vip-preview {
  width: 400px;
  height: auto;
}

.public {
  background-image: url("./images/magbg.jpg");
  background-size: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  flex-direction: column;
}
.public img.section-header {
  margin: 0.5rem;
  max-width: 1000px;
}
.public a {
  display: inline;
}
.public .events {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1200px;
}
.public .events .events-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}
.public .events .latest {
  display: flex;
  width: 80%;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 1.2rem;
  white-space: nowrap;
}
.public .events .latest .date {
  display: flex;
  flex-wrap: nowrap;
  font-family: "kabel-heavyheavy";
  width: 25%;
  padding: 0.5rem;
}
.public .events .latest .date strong {
  display: inline-flex;
  white-space: nowrap;
}
.public .events .latest .name-location {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  padding: 0.5rem;
  font-family: "kabel-heavyheavy";
  white-space: nowrap;
  padding-right: 1rem;
  padding-left: 1rem;
}
.public .events .latest .name-location a {
  color: #f5c6e2;
  display: inline-flex;
}
.public .events .latest .type {
  display: flex;
  flex-wrap: nowrap;
  padding: 0.5rem;
  font-family: "kabel-heavyheavy";
  width: 15%;
  justify-content: flex-end;
}
.public .events h6 {
  width: 30%;
}
.public .schedule {
  width: 100%;
  display: inline-block;
  font-size: 3rem;
}
.public .year {
  font-family: "kabel-heavyheavy", sans-serif;
  font-size: 1.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
}
.public .sightings {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.public .sightings div {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: max-content;
}
.public .sightings p {
  padding: 1rem;
}
.public .sightings .image-app {
  width: fit-content;
  height: max-content;
  height: auto;
}
.public .sightings .image-app img.image-gallery-image {
  margin: 3rem;
  box-shadow: 0 0 40px white;
  width: 80%;
  max-width: 1000px;
  border-radius: 5px;
}

@media (max-width: 1200px) {
  .latest {
    width: 100%;
    font-size: 1rem;
    padding: 0;
  }

  .name-location .date .type {
    font-size: 1rem;
    padding: 0;
  }

  .schedule {
    font-size: 1.8rem;
  }

  .public .sightings {
    width: 90%;
  }
}
@media (max-width: 800px) {
  .public {
    padding: 0;
  }
  .public .events {
    padding: 0;
    padding: 0;
  }
  .public .events .latest {
    width: 100%;
    padding: 0;
  }
  .public .events img.header-image {
    width: 50%;
    max-width: 600px;
    min-width: 100px;
  }
  .public .events .date {
    width: 20%;
    padding: 0;
  }
  .public .events .name-location {
    width: 60%;
    padding: 0;
  }
  .public .schedule {
    padding: 0;
  }
  .public .type {
    padding: 0;
    width: 20%;
  }
  .public .image-app p {
    all: initial;
  }
  .public img.image-gallery-image {
    width: 100%;
    max-width: 500px;
  }
  .public .event-list {
    width: 30%;
  }
}
@media (max-width: 500px) {
  .public {
    padding: 0;
  }
  .public .events {
    padding: 0;
    min-width: 350px;
  }
  .public .events .latest {
    width: 100%;
    font-size: 0.9rem;
    padding: 0;
  }
  .public .events .name-location .date .type {
    font-size: 0.9rem;
    padding: 0;
  }
  .public .events .schedule {
    font-size: 1.5rem;
  }
  .public .events .date {
    width: 15%;
    padding: 0;
  }
}
.bio-container {
  display: flex;
  width: 100%;
}
.bio-container .bio {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
.bio-container .bio::before {
  content: "";
  background-image: url("./images/goddess.jpg");
  background-color: black;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 850px auto;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 1;
}
.bio-container .text {
  position: relative;
  right: 0;
  top: 0;
  left: -100px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  width: 50%;
}
.bio-container .text p {
  font-family: "kabel-heavyheavy";
  text-align: center;
  padding: 1.3rem;
  font-size: 1.5rem;
  color: #fff;
  filter: drop-shadow(2px 2px 2px #000);
}
.bio-container .text img {
  margin-top: 30%;
  width: 70%;
  height: auto;
}

@media (max-width: 1600px) {
  .bio-container .bio::before {
    background-size: 750px auto;
  }
  .bio-container .text {
    margin-right: 1%;
  }
  .bio-container .text p {
    padding: 0.5rem;
    font-size: 1.2rem;
  }
}
@media (max-width: 1200px) {
  .bio-container .text {
    left: 0px;
  }
  .bio-container .text p {
    padding: 0.5rem;
    font-size: 1rem;
  }
  .bio-container .text img {
    margin-top: 15%;
  }
}
@media (max-width: 801px) {
  .bio-container .bio::before {
    background-size: 650px auto;
    opacity: 0.75;
  }
  .bio-container .text {
    width: 50%;
  }
  .bio-container .text p {
    width: 100%;
    font-size: 1rem;
  }
}
@media (max-width: 600px) {
  .bio-container .bio::before {
    opacity: 0.5;
  }
  .bio-container .text {
    min-width: 320px;
    width: 100%;
  }
  .bio-container .text p {
    width: 90%;
    font-size: 0.9rem;
  }
  .bio-container .text img {
    margin-top: 0%;
  }
}
div.connect {
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 100%;
  background-color: black;
}
div.connect span {
  margin-top: 10%;
}
div.connect a.social-link {
  font-family: "kabel-heavyheavy";
  font-size: 1.5rem;
  text-align: center;
  font-weight: 400;
  text-decoration: none;
  margin-top: 5%;
  color: #ca3994;
}
div.connect a.social-link:hover {
  color: #f5c6e2;
}
div.connect .social-link {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  width: 30%;
  min-height: fit-content;
}
div.connect .social-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.element:nth-child(0) {
  animation: my-animation-0 2s ease-out infinite;
}

@keyframes my-animation-0 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(1) {
  animation: my-animation-1 2s ease-out infinite;
}

@keyframes my-animation-1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(2) {
  animation: my-animation-2 2s ease-out infinite;
}

@keyframes my-animation-2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(3) {
  animation: my-animation-3 2s ease-out infinite;
}

@keyframes my-animation-3 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(30px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(4) {
  animation: my-animation-4 2s ease-out infinite;
}

@keyframes my-animation-4 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(40px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(5) {
  animation: my-animation-5 2s ease-out infinite;
}

@keyframes my-animation-5 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(50px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(6) {
  animation: my-animation-6 2s ease-out infinite;
}

@keyframes my-animation-6 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(60px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(7) {
  animation: my-animation-7 2s ease-out infinite;
}

@keyframes my-animation-7 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(70px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(8) {
  animation: my-animation-8 2s ease-out infinite;
}

@keyframes my-animation-8 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(80px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(9) {
  animation: my-animation-9 2s ease-out infinite;
}

@keyframes my-animation-9 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(90px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.element:nth-child(10) {
  animation: my-animation-10 2s ease-out infinite;
}

@keyframes my-animation-10 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.slide-in-right {
  animation: slide-in-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
  0% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-out-left {
  animation: slide-out-left 5s cubic-bezier(0.55, 0.085, 0.68, 0.53) 5s both;
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-1000px);
    opacity: 0;
  }
}
.color-change-5x {
  animation: color-change-5x 8s linear infinite alternate both;
}

@keyframes color-change-5x {
  0% {
    background: #19dcea;
  }
  25% {
    background: #b22cff;
  }
  50% {
    background: #ea2222;
  }
  75% {
    background: #f5be10;
  }
  100% {
    background: #3bd80d;
  }
}
html {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  background: black;
  color: white;
}

.loading {
  font-family: "victorian_parlor_vintage_alternatvictorianparlorvintagealternatefree";
  font-size: 10rem;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  font-family: "kabel-heavyheavy", sans-serif;
}

.show {
  display: flex;
}

.hide {
  display: none;
}

#root {
  box-sizing: border-box;
  max-width: 100%;
  min-height: 100%;
  background: black;
}

header {
  width: 100%;
  display: flex;
  height: 4rem;
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 500;
}

main {
  background: black;
  width: 100%;
  display: flex;
  flex-direction: column;
}

footer {
  height: 2rem;
  display: flex;
  justify-content: center;
  background-color: #000;
  align-items: flex-end;
  border-top: 1px solid white;
  position: static;
  bottom: 0;
  width: 100%;
  height: 3rem;
  z-index: 10;
}

a {
  text-decoration: none;
  color: #ca3994;
}
a:hover {
  color: #f5c6e2;
}

.tiny-text {
  margin-top: -0.8rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 0.9rem;
}

.tiny-text-big {
  margin-top: -0.8rem;
  line-height: 0rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 4rem;
  color: #ca3994;
}

.inner-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.big {
  font-family: "little_lord_fontleroy_nfRg";
  font-size: 8rem;
  font-weight: lighter;
  margin: 1rem;
  text-align: center;
  display: block;
}

.section-header {
  font-family: "little_lord_fontleroy_nfRg";
  font-size: 5rem;
  font-weight: lighter;
  text-align: center;
  display: block;
}

.section-header-small {
  font-family: "little_lord_fontleroy_nfRg";
  font-size: 3rem;
  font-weight: lighter;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 3rem;
}

.fontleroy {
  font-family: "little_lord_fontleroy_nfRg", sans-serif;
}

.kabel {
  font-family: "kabel-heavyheavy", sans-serif;
}

.big-header {
  font-family: "little_lord_fontleroy_nfRg";
  font-weight: lighter;
  display: flex;
  justify-content: center;
  font-size: 8rem;
  margin-top: 2%;
}

.small-header {
  font-weight: lighter;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  margin-top: 1%;
}

img.header-image {
  margin-bottom: 1rem;
  width: 60%;
  min-width: 350px;
  max-width: 500px;
  height: auto;
}

.small-footnote {
  font-family: "kabel-heavyheavy", sans-serif;
  font-size: 0.9rem;
  text-align: right;
  width: 100%;
}

@media (max-width: 1200px) {
  header {
    height: 3.5rem;
  }

  .big {
    font-size: 6rem;
  }

  .section-header {
    font-size: 3rem;
  }

  img.header-image {
    width: 30%;
  }

  .section-header-small {
    font-size: 2rem;
  }

  .big-header {
    font-size: 6rem;
  }

  .small-header {
    font-size: 2rem;
  }

  .small-footnote {
    font-size: 0.9rem;
  }

  .hamburg-icon {
    opacity: 1;
  }
}
@media (max-width: 800px) {
  header {
    height: 3.5rem;
  }

  .big {
    font-size: 5rem;
  }

  .section-header {
    font-size: 2.5rem;
  }

  img.header-image {
    width: 25%;
  }

  .big-header {
    font-size: 5rem;
  }

  .small-header {
    font-size: 1.5rem;
  }

  .small-footnote {
    font-size: 0.9rem;
  }
}
@media (max-width: 600px) {
  .big {
    font-size: 4rem;
  }

  .section-header {
    font-size: 2rem;
  }

  .section-header-small {
    font-size: 1.5rem;
  }

  .big-header {
    font-size: 4rem;
  }

  .small-header {
    font-size: 1.5rem;
  }

  .small-footnote {
    font-size: 0.9rem;
  }
}

