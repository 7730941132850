/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 10, 2022 */



@font-face {
    font-family: 'kabel-heavyheavy';
    src: url('./stylesheets/fonts/webfontkit-20220310-004149/kabel-heavy-webfont.woff2') format('woff2'),
         url('./stylesheets/fonts/webfontkit-20220310-004149/kabel-heavy-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'victorian_parlor_vintage_alternatvictorianparlorvintagealternatefree';
    src: url('./stylesheets/fonts/webfontkit-20220310-004149/victorian_parlor_vintage_alternate_free-webfont.woff2') format('woff2'),
         url('./stylesheets/fonts/webfontkit-20220310-004149/victorian_parlor_vintage_alternate_free-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'little_lord_fontleroy_nfRg';
    src: url('./stylesheets/fonts/webfontkit-20220310-004149/littlelordfontleroy.regular-webfont.woff2') format('woff2'),
         url('./stylesheets/fonts/webfontkit-20220310-004149/littlelordfontleroy.regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
