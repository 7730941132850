.navbar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, black 25%, black 100%);
  ul {
    // transition: opacity 0.4s ease, transform 1s ease, visibility 0.4s;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    list-style: none;
    padding: 0;
    a {
      display: flex;
      align-items: center;
      align-content: center;
    }
  }
  a {
    z-index: 0;
    display: flex;
    text-decoration: none;
    font-family: 'kabel-heavyheavy';
    font-size: 1.6rem;
  }
}
div.hamburg-icon {
  color: #ca3994;
  position: absolute;
  padding: 0.5rem;
  top: -60px;
  right: 0;
  align-items: center;
  height: 100%;
  // transition: transform 0.6s ease;
  align-self: flex-end;
  opacity: 0;
  z-index: 100; // make sure it's on top of the navbar
}

.eighteen {
  width: 2rem;
  position: relative;
  top: -2rem;
  right: -2rem;
  color: white;
  font-size: 1rem;
}
.youtube-nav {
  padding: 0 0.5rem 0 0.5rem;
  margin-top: auto;
  color: white;
  font-size: 1rem;
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
}
.menu-container {
  display: flex;
  flex-direction: column;
  .menu {
    z-index: -10;
    width: 20%;
    // border: 1px solid white;
    position: absolute;
    top: 2.5rem;
    right: 0;
    flex-direction: column;
    height: 100vh;
    // transform: translateY(5rem);
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0) 100%
    );
    :hover {
      display: flex;
      opacity: 1;
    }
  }
  .active {
    display: flex;
    opacity: 1;
    // transform: translateY(4rem);
    // transition: opacity 0.4s ease, transform 1s ease, visibility 0.4s;
    a {
      margin-top: 1rem;
      margin-left: 2rem;
      padding-left: 1.5rem;
    }
  }
  .inactive {
    display: none;
    opacity: 0;
  }
}
.small-screen-menu {

}
.close {
  visibility: hidden;
}
.open {
  visibility: visible;
}
.vip-menu-toggle {
  display: flex;
  svg {
    position: absolute;
    top: 1.5rem;
    right: 2%;
    color: #ca3994;
    width: 1.3rem;
  }
}
.vip-close {
  z-index: 200;
}
///////////////////// less than 1200px ////////////////////////

@media (max-width: 1200px) {
  .navbar {
    a {
      margin: 0 0rem 0 0;
      padding-right: 0;
      font-size: 1.2rem;
    }
  }
  .youtube-nav {
    svg {
      width: 0.9rem;
      height: 0.9rem;
    }
  }
  .eighteen{
      top: -1.7rem;
  right: -2.4rem;
    font-size: .8rem;
  }

}
///////////////////// less than 800px ////////////////////////
@media (max-width: 800px) {
  .navbar {
    align-items: flex-end;
    background: transparent;
    position: relative;
    top: 0;
    bottom: 0;
    margin-top: 2rem;
    a {
      text-align: left;
      padding: 0 1.2rem 0 1.2rem;
    }
    ul {
          opacity: 0;
      height: 100vh;
      width: 250px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      // transform: translateY(-10px);
      // transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 80%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    li {
      padding: 0.5rem;
    }
    .active {
      display: flex;
      opacity: 1;
      a {
        margin-top: 0rem;
        margin-left: 4rem;
      }
    }
    .inactive {
      display: none;
    }
  }
  .menu-container {
    .menu {
      top: 13.2rem;
      right: 0rem;
      width: 230px;

      // transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
      :hover {
        display: flex;
      }
      a {
        padding-left: 2rem;
      }
    }
  }
  div.hamburg-icon {
    top: 0;
    opacity: 1;
    button{
      z-index: 100;
    }
  }
    .eighteen {
  width: 2rem;
  position: relative;
  top: -.3rem;
  right: -.3rem;
  color: white;
  font-size: 1rem;
  }
  .youtube-nav {
    svg {
      width: 0.9rem;
      height: 0.9rem;
    }
  }

}
