div.gallery-list {
  max-width: 100%;
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  li {
    width: calc(1 / 4 * 100%);
    margin: 0 1rem;
    padding: 0;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }
}

// for the image gallery
section.app{
  max-width: 100%;
  min-height: 90vh;
  display: flex;

  margin: 1rem;
  .image-gallery-content{
  position: unset;
  width: 90vw;
  max-width:100%;
  max-height:100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.image-gallery-slide.center{
  max-width: fit-content;
  margin: auto;
}

}

.gallery-container {
  width: 100%;
  height: 100%;
}

.app-header {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.play-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
}

.play-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.play-button:after {
  content: "";
  display: block;
  position: absolute;
  top: 16.5px;
  left: 40px;
  margin: 0 auto;
  border-style: solid;
  border-width: 12.5px 0 12.5px 20px;
  border-color: transparent transparent transparent rgba(255, 255, 255, 1);
}

.close-video::before {
  content: '✖';
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  padding: 20px;
  z-index: 1;
  line-height: .7;
  display: block;
  color: #fff;
}

.video-wrapper {
  position: relative;
  padding: 33.35% 0; /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.app .image-gallery {
  margin: 0 auto;
  // width: 100vw;
  // border: rgb(115, 0, 0) solid 1px;
  display: flex;
}

.app-buttons li {
  display: block;
}

@media (max-width: 768px) {

  .app-header {
    font-size: 20px;
  }

  .app-buttons li {
    display: block;
    margin: 10px 0;
  }

  .app-buttons li + li {
    padding: 0;
  }

  .play-button {
    height: 40px;
    width: 65px;
  }

  .play-button:after {
    top: 11px;
    left: 27px;
    border-width: 8.5px 0 8.5px 12px;
  }

  .close-video::before {
    font-size: 16px;
    padding: 15px;
  }
}

@media (max-width: 1024px) {
  .app .image-gallery {
    width: 100%;
    height: 100%
  }
}

.app-interval-input-group {
  display: table;
}

.app-interval-label {
  display: table-cell;
  vertical-align: middle;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 3px solid #ccc;
  border-right: none;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.app-interval-input {
  -webkit-appearance: none;
  display: table-cell;
  margin: 0;
  padding: 9px;
  border-radius: 5px;
  font-size: 14px;
  border: 3px solid #ccc;
  background: #fff;
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.app-interval-input {
  width: 65px;
}


