.vip {
  background-color: black;
 .slide-show{
    display: flex;
    width: 90%;

  .slide-container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .slide{
      width: 100%;
      transition: transform 0.5s ease-in-out;
    }

  }
  }
  .vip-preview{
    width: 400px;
    height: auto;

  }
}
//start temp css
