.public {
  background-image: url('./images/magbg.jpg');
  background-size: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 5%;
  flex-direction: column;

  img.section-header {
    margin: 0.5rem;
    max-width: 1000px;
  }
  a {
    display: inline;
  }
  .events {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 1200px;
    .events-list{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 95%;
    }
    .latest {
      display: flex;
      width: 80%;
      flex-direction: row;
      flex-wrap: nowrap;
      font-size: 1.2rem;
      white-space: nowrap;

      .date {
        display: flex;
        flex-wrap: nowrap;
        font-family: 'kabel-heavyheavy';
        width: 25%;
        padding: 0.5rem;
        strong {
          display: inline-flex;
          white-space: nowrap;
        }
      }
      .name-location {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 60%;
        padding: 0.5rem;
        font-family: 'kabel-heavyheavy';
        white-space: nowrap;
        padding-right: 1rem;
        padding-left: 1rem;
        a {
          color: #f5c6e2;
          display: inline-flex;
        }
      }
      .type {
        display: flex;
        flex-wrap: nowrap;
        padding: 0.5rem;
        font-family: 'kabel-heavyheavy';
        width: 15%;
        justify-content: flex-end;
      }
    }
    h6 {
      width: 30%;
    }
  }
  .schedule {
    width: 100%;
    display: inline-block;
    font-size: 3rem;
  }
  .year {
    font-family: 'kabel-heavyheavy', sans-serif;
    font-size: 1.5rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .sightings {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: max-content;
    }
    p {
      padding: 1rem;
    }
    .image-app {
      width: fit-content;
      height: max-content;
      height: auto;

      img.image-gallery-image {
        margin: 3rem;
        box-shadow: 0 0 40px rgb(255, 255, 255);
        width: 80%;
        max-width: 1000px;
        border-radius: 5px;
      }
    }
  }
}
@media (max-width: 1200px) {

  .latest {
    width: 100%;
    font-size: 1rem;
    padding: 0;
  }
  .name-location .date .type {
    font-size: 1rem;
    padding: 0;
  }
  .schedule {
    font-size: 1.8rem;
  }

  .public {
    .sightings {
      width: 90%;
    }
  }
}
@media (max-width: 800px) {
  .public {
    padding: 0;
    .events {
      padding: 0;

      .latest {
        width: 100%;
        padding: 0;
      }
      img.header-image {
        width: 50%;
        max-width: 600px;
        min-width: 100px;
      }

    .date {
      width: 20%;
      padding: 0;
    }
    .name-location {
      width: 60%;
      padding: 0;
    }
    padding: 0;
  }
  .schedule {
    padding: 0;
  }
  .type {
    padding: 0;
    width: 20%;
  }
  .image-app {
    p {
      all: initial;
    }
  }
        img.image-gallery-image {
          width: 100%;
          max-width: 500px;
        }
          .event-list{
   width: 30%;
  }
  }
}

@media (max-width: 500px) {
  .public {
    padding: 0;
    .events {
      padding: 0;
      min-width: 350px;




  .latest {
    width: 100%;
    font-size: .9rem;
    padding: 0;
  }
  .name-location .date .type {
    font-size: .9rem;
    padding: 0;
  }
  .schedule {
    font-size: 1.5rem;
  }
    .date {
      width: 15%;
      padding: 0;
    }
  //   .name-location {
  //     width: 60%;
  //     padding: 0;
  //   }
  //   padding: 0;
  // }
  // .schedule {
  //   padding: 0;
  // }
  // .type {
  //   padding: 0;
  //   width: 20%;
  // }
  // .image-app {
  //   p {
  //     all: initial;
  //   }
  // }
  //       img.image-gallery-image {
  //         width: 100%;
  //         max-width: 500px;
  //       }
  //         .event-list{
  //  width: 30%;
  // }
  }
}
  }
