.video-preview {
  display: flex;
}
.ff-inactive{
      border-radius: 20px;

}
.video-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    // max-width: 1600px;
    min-width: 90%;

    .gif-holder {
      padding: 1rem;

      div{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      }
  }
  .freezeframe{
    padding: 1rem;
    margin: 1rem;
    border-radius: 20px;
    filter: drop-shadow(0 0 .3rem rgb(222, 89, 232));
    background-color: rgba(0, 0, 0, 0.80);
  }
}
