@use './stylesheets/fonts/webfontkit-20220310-004149/fonts.scss';
@use './components/Home/home.scss';
@use './stylesheets/image-gallery.scss';
@use './components/GalleryHome/gallery.scss';
@use './components/VideoHome/video.scss';
@use './components/Navigation/navigation.scss';
@use './components/VIPHome/vip.scss';
@use './components/Appearances/appearances.scss';
@use './components/Biography/biography.scss';
@use './components/Social/social.scss';
@use 'stylesheets/theme.scss';
@use 'stylesheets/animations.scss';
html {
  box-sizing: border-box;
}
body {
  overflow: hidden;
  margin: 0;
  $font-family: (
    -apple-system,
    BlinkMacSystemFont,
    'victorian_parlor_vintage_alternatvictorianparlorvintagealternatefree',
    'kabel-heavyheavy',
    'little_lord_fontleroy_nfRg',
    sans-serif
  );
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: scroll;
  background: black;
  color: white;
}
.loading {
  font-family: 'victorian_parlor_vintage_alternatvictorianparlorvintagealternatefree';
  font-size: 10rem;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
p {
  font-family: 'kabel-heavyheavy', sans-serif;
}
.show {
  display: flex;
}
.hide {
  display: none;
}
#root {
  box-sizing: border-box;
  max-width: 100%;
  min-height: 100%;
  background: rgb(0, 0, 0);
}
header {
  width: 100%;
  display: flex;
  // justify-content: center;
  height: 4rem;
  left: 0;

  position: -webkit-sticky;
  position: sticky;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 500;
}
main {
  background: black;
  width: 100%;
  display: flex;
  flex-direction: column;
}
// .main{
//   background-color: #000;
//   width: 100vw;
//   height: 100vh;
//   display: flex;
// }
footer {
  height: 2rem;
  display: flex;
  justify-content: center;
  background-color: #000;
  align-items: flex-end;
  border-top: 1px solid rgb(255, 255, 255);
  position: static;
  bottom: 0;
  width: 100%;
  height: 3rem;
  z-index: 10;
}
a {
  text-decoration: none;
  color: #ca3994;
  &:hover {
    color: #f5c6e2;
  }
}

.tiny-text {
  margin-top: -0.8rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 0.9rem;
}

.tiny-text-big {
  margin-top: -0.8rem;
  line-height: 0rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 4rem;
  color: #ca3994;
}
.inner-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.big {
  font-family: 'little_lord_fontleroy_nfRg';
  font-size: 8rem;
  font-weight: lighter;
  margin: 1rem;
  text-align: center;
  display: block;
}
.section-header {
  font-family: 'little_lord_fontleroy_nfRg';
  font-size: 5rem;
  font-weight: lighter;
  text-align: center;
  display: block;
}
.section-header-small {
  font-family: 'little_lord_fontleroy_nfRg';
  font-size: 3rem;
  font-weight: lighter;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 3rem;
}
.fontleroy {
  font-family: 'little_lord_fontleroy_nfRg', sans-serif;
}
.kabel {
  font-family: 'kabel-heavyheavy', sans-serif;
}
.big-header {
  font-family: 'little_lord_fontleroy_nfRg';
  font-weight: lighter;
  display: flex;
  justify-content: center;
  font-size: 8rem;
  margin-top: 2%;
}
.small-header {
  font-weight: lighter;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  margin-top: 1%;
}
img.header-image {
  margin-bottom: 1rem;
  width: 60%;
  min-width: 350px;
  max-width: 500px;
  height: auto;
}
.small-footnote {
  font-family: 'kabel-heavyheavy', sans-serif;
  font-size: 0.9rem;
  text-align: right;
  width: 100%;
}

// media queries
///////////////////// less than 1200px ////////////////////////

@media (max-width: 1200px) {
    header{
    height: 3.5rem;
  }
  .big {
    font-size: 6rem;
  }
  .section-header {
    font-size: 3rem;
  }
   img.header-image {
    width: 30%;
  }
  .section-header-small {
    font-size: 2rem;
  }
  .big-header {
    font-size: 6rem;
  }
  .small-header {
    font-size: 2rem;
  }
  .small-footnote {
    font-size: 0.9rem;
  }
  .hamburg-icon {
    // transition: transform 0.6s ease;
    opacity: 1;
  }
}
///////////////////// less than 800px ////////////////////////

@media (max-width: 800px) {
  header{
    height: 3.5rem;
  }
  .big {
    font-size: 5rem;
  }
  .section-header {
    font-size: 2.5rem;
  }
  img.header-image {
    width: 25%;
  }
  .big-header {
    font-size: 5rem;
  }
  .small-header {
    font-size: 1.5rem;
  }
  .small-footnote {
    font-size: 0.9rem;
  }
}
///////////////////// less than 600px ////////////////////////

@media (max-width: 600px) {
  .big {
    font-size: 4rem;
  }
  .section-header {
    font-size: 2rem;
  }
  .section-header-small {
    font-size: 1.5rem;
  }
  .big-header {
    font-size: 4rem;
  }
  .small-header{
    font-size: 1.5rem;
  }
  .small-footnote{
    font-size: .9rem;
  }
}
