div.connect {
  // border: #ca3994 solid 1px;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 100%;
  background-color: black;
    span{
    margin-top: 10%;
  }

  a.social-link {
    font-family: 'kabel-heavyheavy';
    font-size: 1.5rem;
    text-align: center;
    font-weight: 400;
    text-decoration: none;
    margin-top: 5%;
    color: #ca3994;
    &:hover {
      color: #f5c6e2;
    }
  }
  .social-link {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    width: 30%;
    min-height: fit-content;
  }
  .social-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 1rem;
  }

}
