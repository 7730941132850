//transition: <property> <duration> <timing-function> <delay>;
$total: 10;
$delay: 1s;
$duration: 2s;

@for $n from 0 through $total {
  .element:nth-child(#{$n}) {
    animation: my-animation-#{$n} $duration ease-out infinite;
  }

  @keyframes my-animation-#{$n} {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate($n * 10px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}

.slide-in-right {
  animation: slide-in-right 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
    0% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-out-left {
	animation: slide-out-left 5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 5s both;
}
@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-1000px);
    opacity: 0;
  }
}

.color-change-5x {
  animation: color-change-5x 8s linear infinite alternate both;
}
@keyframes color-change-5x {
  0% {
    background: #19dcea;
  }
  25% {
    background: #b22cff;
  }
  50% {
    background: #ea2222;
  }
  75% {
    background: #f5be10;
  }
  100% {
    background: #3bd80d;
  }
}
